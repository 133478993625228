#footer{
    background: #f2f5f8;
    padding: 0 0 30px 0;
    font-size: 14px;
    color: rgb(102,102,119);   
    margin-top: 30px;     
}.footer-list{
    list-style-type: none;
    padding-left: 0;
    line-height: 2;
}#footer h5{
    font-family: "Latha"!important;
    font-size: 18px;
    font-weight: 700;        
}.footer-link{
    color: #667;
}.footer-social-networks{
    font-size: 32px;
    color: #666;
    margin-right: 5px;
    padding: 10px;
    transition: 0.2s;
    font-weight: bold;
}.footer-bottom-leyend{
    font-size: 16px;
    text-align: center !important;
}
a{
    text-decoration: none;
}